$(() => {
    window.validation_methods = {
        debounce: function (func, wait, immediate)
        {
            var timeout;
            return function() {
                var context = this, args = arguments;
                var later = function() {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                var callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        },
        match: function(input1, input2)
        {
            return function()
            {
                message = ''
                if(input1.val() !== input2.val()) message = 'The provided passwords do not match.';
                input1[0].setCustomValidity(message);
                input1.trigger('input.validation-error')
                return message === '';
            };
        },
        email_check: function()
        {
            return this.debounce(function(e)
            {
                var $input=  $(this);
                var email = $input.val();

                axios.post('/email-check', {
                    email: email,
                }).then(function(response){
                    message = ''
                    if(!response.data) message = 'The provided email is already in use.';
                    $input[0].setCustomValidity(message);
                    $input.trigger('input.validation-error')
                });
            }, 500);
        },
        required_count: function(input_selector, needed)
        {
            var $group = $(input_selector).closest('.form-group');
            var $inputs = $(input_selector);

            return function()
            {
                var current = $group
                    .find('input')
                    .filter(':checked')
                    .get();

                var valid = current.length >= needed;

                message = ''
                if(!valid) message = 'You must select atleast '+needed+' option(s).';
                $inputs.each((i,e) => e.setCustomValidity(message));
                return message === '';
            };
        }
    };

    $('[data-validation-error]').each(function(k, e){
        var $message = $(e);
        var $inputs = $($message.data('validation-error'));
        $inputs.on('input.validation-error', function(){
            $message.text($inputs[0].validationMessage);
        });
    });
});
