$(() => {
    $('.needs-validation .likert').each(function () {
        var $container = $(this);
        var $input = $container.find('li:first-of-type input')[0];
        $container.toggleClass('is-invalid', !$input.checkValidity());
    });


    $('.likert').on('change', 'input', function () {
        var $this = $(this);
        var $container = $this.closest("ul");
        /* var start_index = $container.attr('start-index') || 0;
        $container
            .children()
            .removeClass("active-segment")
            .slice(0, $this.val() - start_index)
            .addClass("active-segment");
 */
        if($container.parents('.needs-validation').length) $container.toggleClass('is-invalid', !this.checkValidity());
    });
});
