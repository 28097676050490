$(() => {
    $('.needs-validation .eight_scale').each(function () {
        var $container = $(this);
        var $input = $container.find('li:first-of-type input')[0];
        $container.toggleClass('is-invalid', !$input.checkValidity());
    });

    $(".eight_scale").on("change", "input", function () {
        var $this = $(this);
        var $container = $this.closest("ul");
        /* $container
            .children()
            .removeClass("active-segment")
            .slice(0, $this.val() - 1)
            .addClass("active-segment"); */

        if($container.parents('.needs-validation').length) $container.toggleClass('is-invalid', !this.checkValidity());
    });
});
