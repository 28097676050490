$(() => {
    var methods = {
        _construct: function()
        {
            var $data = this.data('conditionals');
            if($data === undefined) $data = methods.init.apply(this);
            return $data;
        },
        init: function()
        {
            // this.closest('.form-group').css('background-color', '#FFAAFF');
            this.data('conditionals', {
                'visibility': {},
                'required': {}
            });

            this.on('change', methods.onchange);

            return this.data('conditionals');
        },
        setdata: function(type, selector, value)
        {
            var $data = methods._construct.apply(this);
            if($data[type][value] === undefined) $data[type][value] = [];
            $data[type][value].push($(selector));
            methods.onchange.apply(this[0]);
        },
        onchange: function()
        {
            var $this = $(this);
            var $data = methods._construct.apply($this);

            $.each($data['visibility'], (value, list) => {
                var $state = methods.checkstate.apply($this, [value]);
                $.each(list, (k, elements) => elements.closest('.form-group').toggle($state));
            });

            $.each($data['required'], (value, list) => {
                var $state = methods.checkstate.apply($this, [value]);
                $.each(list, (k, elements) => {
                    elements.each((k, e) => {
                        e.required = $state;
                    });
                });
            });
        },
        checkstate: function(value)
        {
            if(this.attr('type') == 'checkbox'){
                var current = this
                    .closest('.form-group')
                    .find('input')
                    .filter(':checked')
                    .map((i,e) => $(e).val())
                    .get();
                return current.includes(value);
            }

            return this.val() === value;
        }
    };
    $.fn.conditionals = function(method) {
        return this.each((k, v) => {
            return methods.setdata.apply($(v), arguments);
        });
    };
});
